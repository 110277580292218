import React, { useState, useEffect } from 'react';
import { Home, VideoCall, History, Settings, BarChart, Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import { getDokanUsers } from './api'; // Assurez-vous d'importer la fonction pour obtenir les vendeurs
import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://srv493134.hstgr.cloud';

function Dashboard({ products, vendors }) {
  const [activeMenu, setActiveMenu] = useState('Home');
  const [subMenuSelection, setSubMenuSelection] = useState(null);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [adminName, setAdminName] = useState('Admin');
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('form');
  const [popupMessage, setPopupMessage] = useState('');
  const [datenle, setDatenle] = useState('');
  const [stores, setStores] = useState([]); // Initialis� en tant que tableau
  const [vendorList, setVendorList] = useState([]); // Initialis� en tant que tableau vide
  const [liveEvents, setLiveEvents] = useState([]); // �tat pour les �v�nements en direct
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchVendors() {
      try {
        const vendors = await getDokanUsers();
        setVendorList(vendors);
      } catch (error) {
        console.error('Erreur lors de la r�cup�ration des vendeurs:', error);
      }
    }
    fetchVendors();
  }, []);

  useEffect(() => {
    // Fonction pour r�cup�rer les donn�es des live events depuis le fichier JSON
    const fetchLiveEvents = async () => {
      try {
        const response = await axios.get('https://srv493134.hstgr.cloud/go-live/events.json', {
          headers: {
            'Accept': 'application/json'
          }
        });
        if (response.headers['content-type'].includes('application/json')) {
          console.log('Live events data:', response.data); // Log pour v�rifier les donn�es
          setLiveEvents(response.data);
        } else {
          console.error('La r�ponse n\'est pas au format JSON:', response.data);
        }
      } catch (error) {
        console.error('Erreur lors de la r�cup�ration des donn�es des live events:', error);
      }
    };

    // Appeler la fonction pour r�cup�rer les donn�es
    fetchLiveEvents();
  }, []);

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
  };

  const handleSubMenuToggle = () => {
    setIsSubMenuVisible(!isSubMenuVisible);
  };

  const handleSubMenuClick = (subMenuName) => {
    setSubMenuSelection(subMenuName);
    setIsSubMenuVisible(false);
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      window.location.href = 'https://live-discount.com';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleProfileHover = () => {
    setShowLogoutMenu(true);
  };

  const handleProfileLeave = () => {
    setShowLogoutMenu(false);
  };

  const handleCreateLiveEventClick = () => {
    setShowPopup(true);
    setPopupContent('form');
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handlePublish = async () => {
    const eventId = Math.floor(Math.random() * 1000000);
    const liveLink = `https://srv493134.hstgr.cloud/live/${eventId}`;
    const eventName = `Live Event ${eventId}`;
    const startDate = new Date(datenle).toISOString();

    const liveEvent = {
      eventId,
      eventName,
      liveLink,
      startDate,
      stores, // Utilisation de stores pour les magasins s�lectionn�s
    };

    console.log('Sending live event data:', liveEvent); // Log pour v�rifier les donn�es

    try {
      // Enregistrer l'�v�nement en direct dans le fichier JSON via l'API
      const response = await axios.post('https://srv493134.hstgr.cloud/createLiveEvent', liveEvent, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log('Event saved:', response.data);

      setPopupMessage(`Votre �v�nement en direct est disponible ici : ${liveLink}`);
      setPopupContent('confirmation');
    } catch (e) {
      console.error('Error saving event:', e);
      setPopupMessage('Error creating Live Event. Please try again.');
    }
  };

  const handleStoreSelection = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);


    const selectedStores = selectedOptions.map(option => ({
      IDVendor: option.value,
      name: option.text
    }));
    setStores(selectedStores);
  };

  const renderContent = () => {
    if (activeMenu === 'Analytics') {
      return (
        <div>
          {subMenuSelection === 'Live Events' && (
            <div id="bloch3ale">Contenu des sous-�l�ments Live Events</div>
          )}
          {subMenuSelection === 'Private Lives' && (
            <div id="bloch3apl">Contenu des sous-�l�ments Private Lives</div>
          )}
          {subMenuSelection === 'Stores' && (
            <div id="bloch3as">Contenu des sous-�l�ments Stores</div>
          )}
          {!subMenuSelection && <div id="bloch3">Contenu par d�faut pour Analytics</div>}
        </div>
      );
    }

    // Filtrer les �v�nements en direct pour n'inclure que ceux avec le statut "Valid"
    const validLiveEvents = liveEvents.filter(event => event.status === 'Valid');

    switch (activeMenu) {
      case 'Home':
        return (
          <div id="bloch3home">
            <button className="boutoncle" onClick={handleCreateLiveEventClick}>
              <Add /> CREATE A LIVE EVENT
            </button>
            <div className="bloch3ple">
              <div className="carousel-container">
                <div className="carousel">
                  {validLiveEvents.map((event, index) => (
                    <div key={index} className="live-event">
                      <div className="event-date" style={{ fontWeight: 'bold' }}>{event.Date}</div>
                      <div className="event-time">{event.startDateVendor} - {event.endDateVendor}</div>
                      <div className="vendor-info">
                        <div className="vendor-name">{event.storeVendor.map(vendor => vendor.name).join(', ')}</div>
                      </div>
                      <div className="product-count">{event.Products.length} Products</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="bloch3ppl">Contenu bloch3ppl</div>
            <div className="titretn">Today's news</div>
            <div className="bloch3total">Contenu bloch3total</div>
          </div>
        );
      case 'Live Events':
        return (
          <div id="bloch3le">
            <div className="bloch3ple">
              <div className="carousel-container">
                <div className="carousel">
                  {validLiveEvents.map((event, index) => (
                    <div key={index} className="live-event">
                      <div className="event-date" style={{ fontWeight: 'bold' }}>{event.Date}</div>
                      <div className="event-time">{event.startDateVendor} - {event.endDateVendor}</div>
                      <div className="vendor-info">
                        <div className="vendor-name">{event.storeVendor.map(vendor => vendor.name).join(', ')}</div>
                      </div>
                      <div className="product-count">{event.Products.length} Products</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="titreue">Upcoming events</div>
            <div className="bloch3ue">Contenu bloch3ue</div>
            <div className="titreoe">Older events</div>
            <div className="bloch3oe">Contenu bloch3oe</div>
          </div>
        );
      case 'Private Lives':
        return (
          <div id="bloch3pl">
            <div className="bloch3prl">Contenu bloch3prl</div>
            <div className="titreueprivate">Upcoming events</div>
            <div className="bloch3ueprivate">Contenu bloch3ueprivate</div>
            <div className="titreoeprivate">Older events</div>
            <div className="bloch3oeprivate">Contenu bloch3oeprivate</div>
          </div>
        );
      case 'History':
        return <div id="bloch3history">Contenu pour l'historique des �v�nements</div>;
      case 'Control Panel':
        return <div id="bloch3cp">Contenu pour le panneau de configuration</div>;
      default:
        return <div id="bloch3">Contenu par d�faut</div>;
    }
  };

  return (
    <div className="dashboard-container">
      <div className="bloc1">
        <div className="logo-container">
          <img
            src="https://live-discount.com/wp-content/uploads/2023/10/Logo_live_discount-1024x782.png"
            alt="Logo"
            className="logo"
          />
        </div>
        <ul className="menu1">
          <li className={activeMenu === 'Home' ? 'active' : ''} onClick={() => handleMenuClick('Home')}>
            <Home />
            Home
          </li>
          <li
            className={activeMenu === 'Live Events' ? 'active' : ''}
            onClick={() => handleMenuClick('Live Events')}
          >
            <VideoCall />
            Live Events
          </li>
          <li
            className={activeMenu === 'Private Lives' ? 'active' : ''}
            onClick={() => handleMenuClick('Private Lives')}
          >
            <VideoCall />
            Private Lives
          </li>
          <li
            className={activeMenu === 'History' ? 'active' : ''}
            onClick={() => handleMenuClick('History')}
          >
            <History />
            History
          </li>
          <li
            className={activeMenu === 'Control Panel' ? 'active' : ''}
            onClick={() => handleMenuClick('Control Panel')}
          >
            <Settings />
            Control Panel
          </li>
          {/* Ajout de Analytics avec le sous-menu */}
          <li
            className={activeMenu === 'Analytics' ? 'active' : ''}
            onMouseEnter={handleSubMenuToggle}
            onMouseLeave={handleSubMenuToggle}
          >
            <BarChart />
            Analytics
            {isSubMenuVisible && (
              <ul className="submenu">
                <li onClick={() => handleSubMenuClick('Live Events')}>Live Events</li>
                <li onClick={() => handleSubMenuClick('Private Lives')}>Private Lives</li>
                <li onClick={() => handleSubMenuClick('Stores')}>Stores</li>
              </ul>
            )}
          </li>
        </ul>
        <button className="bouton1" onClick={() => (window.location.href = 'https://live-discount.com/')}>
          GO TO DASHBOARD
        </button>
      </div>

      <div className="bloc2">
        <div className="bloch1">
          <span className="admin-name">{adminName}</span>

          <span
            className="admin-icon"
            onMouseEnter={handleProfileHover}
            onMouseLeave={handleProfileLeave}
          >
            <img
              src="https://img.icons8.com/?size=100&id=7820&format=png&color=000000"
              alt="Admin Icon"
            />
            {showLogoutMenu && (
              <div className="admin-menu">
                <a href="#" className="logout-btn" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            )}
          </span>

          <span className="notifications-icon">
            <img src="https://img.icons8.com/material-outlined/24/alarm.png" alt="Notifications" />
          </span>
        </div>

        <div className="bloch2">
          <span className="welcome-text">
            Welcome, <strong style={{ color: '#7fd2f3' }}>{adminName}</strong>
          </span>
        </div>

        <div className="bloch3">{renderContent()} </div>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popupnle">
            {popupContent === 'form' ? (
              <>
                <div className="popup-title">New live event</div>
                <form className="formnle">
                  <label htmlFor="datenle">Date and Time:</label>
                  <input
                    type="datetime-local"
                    id="datenle"
                    value={datenle}
                    onChange={(e) => setDatenle(e.target.value)}
                  />
                  <label htmlFor="stores">Select Stores:</label>
                  <select
                    id="stores"
                    multiple
                    value={stores.map(store => store.IDVendor)}
                    onChange={handleStoreSelection}
                  >
                    {Array.isArray(vendorList) && vendorList.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.first_name} {vendor.last_name}
                      </option>
                    ))}
                  </select>
                </form>
                <div className="popup-actions">
                  <button className="popup-delete" onClick={handlePopupClose}>
                    Delete
                  </button>
                  <button className="popup-publish" onClick={handlePublish}>
                    Publish
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="popup-title">New live</div>
                <p>{popupMessage}</p>
                <button className="popup-close" onClick={handlePopupClose}>
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;